type VariableCurrencies = 'SEK' | 'DKK' | 'AED' | 'MXN'

export const VARIABLE_CURRENCY_MAP: Record<VariableCurrencies, Record<number, number>> = {
	SEK: {
		25: 350,
		50: 700,
		75: 1000,
		100: 1400,
		125: 1750,
		150: 2100,
		200: 2800,
		250: 3500,
		275: 3800,
		300: 4200,
		350: 4900,
		400: 5600,
		450: 6300,
		500: 7000,
		550: 7700,
		600: 8400,
		650: 9100,
		700: 9800,
		5300: 74200, // Special case for "one-item" 100% discounts. This is an arbitrary number
	},
	DKK: {
		25: 350,
		50: 475,
		75: 700,
		100: 950,
		125: 1150,
		150: 1425,
		200: 1900,
		250: 2375,
		275: 2500,
		300: 2700,
		350: 3100,
		400: 3800,
		450: 4400,
		500: 4750,
		550: 5225,
		600: 5700,
		650: 6500,
		700: 7100,
		5300: 53800, // Special case for "one-item" 100% discounts. This is an arbitrary number
	},
	AED: {
		25: 100,
		50: 200,
		75: 300,
		100: 400,
		125: 500,
		150: 600,
		200: 800,
		250: 1000,
		275: 1100,
		300: 1200,
		350: 1300,
		400: 1500,
		450: 1600,
		500: 2000,
		550: 2200,
		600: 2400,
		650: 2500,
		700: 2700,
		5300: 19500, // Special case for "one-item" 100% discounts. This is an arbitrary number
	},
	MXN: {
		25: 500,
		50: 1000,
		75: 1500,
		100: 2000,
		125: 2500,
		150: 3000,
		200: 4000,
		250: 5000,
		275: 5500,
		300: 6000,
		350: 7000,
		400: 8000,
		450: 9000,
		500: 10000,
		550: 11000,
		600: 12000,
		650: 13000,
		700: 14000,
		5300: 97000, // Special case for "one-item" 100% discounts. This is an arbitrary number
	},
}

export const getRandomString = (length = 15): string => {
	return new Array(length)
		.fill(0)
		.map(() => Math.trunc(Math.random() * 36).toString(36))
		.join('')
}
